<template>
  <div
    v-loading="loading"
    class="custom-dashboard-table"
    :element-loading-text="loadingText"
  >
    <div class="d-flex">
      <div class="left-card mt-15" style="min-width: 10vw">
        <el-tooltip
          placement="top-start"
          :content="componentData.component_name"
        >
          <b>
            {{ componentData.component_name | truncate(25, "...") }}
          </b>
        </el-tooltip>
      </div>
      <div class="d-flex right-card mt-1 top-content">
        <div
          class="right-block float-right"
          style="margin-bottom: 5px; display: flex"
        >
          <ul
            class="action-buttons hover-buttons unordered"
            v-if="this.selectedRowsIds.length > 0"
            style="margin-left: 10px"
          >
            <li>
              <el-dropdown>
                <button class="btn btn-primary btn-sm m-lr-1" type="button">
                  <i class="fa fa-warning"></i> Actions
                </button>
                <el-dropdown-menu slot="dropdown">
                  <!-- <a v-if="!isApplicationUserSide" @click="deleteOncheck">
                      <el-dropdown-item command="view"
                        ><i class="fa fa-trash-o"></i>Delete</el-dropdown-item
                      >
                    </a> -->
                  <!-- <a
                      v-if="checkPerimission('BULK UPDATE')"
                      @click="getFieldsForBulkUpdate"
                    >
                      <el-dropdown-item command="view"
                        ><i class="fa fa-pencil"></i>Update</el-dropdown-item
                      >
                    </a> -->
                  <a @click="openBulkSendNotification()">
                    <el-dropdown-item command="view">
                      <i class="fa fa-envelope-o"></i>Send
                      Notifications</el-dropdown-item
                    >
                  </a>
                </el-dropdown-menu>
              </el-dropdown>
            </li>
          </ul>
        </div>
        <el-tooltip
          content="Export Data"
          placement="top"
          v-if="!hide_export"
          class="bt"
        >
          <el-button
          v-if="shouldShowButton"
            @click="exportCSV"
            type="info"
            label="Export"
            class="icon-button"
          >
            <i class="fa fa-upload" aria-hidden="true"></i>
          </el-button>
        </el-tooltip>
        <el-input
          placeholder="Search"
          class="search-width ml-10"
          v-model="search_string"
          @input="searchEntityData"
          clearable
          prefix-icon="el-icon-search"
          size="mini"
          v-if="componentData"
        ></el-input>
        <div class="right-block float-right mlr-1" v-if="hasAddPermission">
          <el-tooltip
            :content="
              currentEntity && currentEntity.name
                ? 'Add new ' + currentEntity.name
                : 'Add Entity Data'
            "
          >
            <button
              class="btn btn-outline-success btn-sm m-lr-1"
              type="primary"
              @click="addEntityData"
            >
              &nbsp;<i class="fa fa-plus-circle fa-lg"></i>&nbsp;
            </button>
          </el-tooltip>
        </div>
        <el-select
          :placeholder="getPlaceholderName"
          v-model="selected_date_picker"
          @change="applyQuickFilter"
          clearable
          size="mini"
          class="picker-options"
          v-if="componentData.date_filter_field"
        >
          <el-option
            v-for="(el, index) in pickerOptions"
            :key="el.value + '_' + index"
            :value="el.value"
            :label="el.label"
            >{{ el.label }}</el-option
          >
        </el-select>
        <!-- <div class="date-picker-container">
        <el-date-picker
          v-if="selectedFileds[field]== 'custom_date'"
          v-model="dateFilter.custom_date"
          :size="fromCD ? 'mini' : 'large'"
          style="width: 100%"
          placeholder="Select custom data"
          @change="applyCustomDateFilter(dateFilter.custom_date,field)"
            format="MM-dd-yyyy" 
        ></el-date-picker>
        </div> -->
        <el-dropdown trigger="click" v-if="!hide_options">
          <el-button
            icon="el-icon-more"
            style="transform: rotate(90deg); height: 40px"
            type="text"
          ></el-button>
          <el-dropdown-menu slot="dropdown">
            <!-- <a @click="openFilterMethod">
              <el-dropdown-item>Filter</el-dropdown-item>
            </a>-->
            <a @click="openConfigDialog" v-if="!hide_options">
              <el-dropdown-item>Edit</el-dropdown-item>
            </a>
            <a @click="deleteComponent" v-if="!hide_options">
              <el-dropdown-item>Delete</el-dropdown-item>
            </a>
          </el-dropdown-menu>
        </el-dropdown>
        <el-button
          class="fullscreen-button"
          icon="el-icon-full-screen"
          type="text"
          v-if="!hide_expend"
          @click="expendTable"
          style="right: 0px !important"
        ></el-button>
      </div>
    </div>
    <el-row class="table-style">
      <el-col :span="24">
        <div
          style="display: flex"
          v-if="currentFilter && currentFilter.quick_filters"
        >
          <QuickEntityFilters
            class="ml-1"
            v-if="currentFilter && currentFilter.quick_filters"
            :key="
              componentData.entity_id +
              '_' +
              index +
              '_' +
              componentData.filter_id +
              '_table_filters'
            "
            :filterFields="currentFilter.quick_filters"
            :allFields="selectedEntityFields"
            :selectedFileds="selectedFileds"
            @quick-filter-updated="applyQuickFilter"
            :fromCD="true"
          ></QuickEntityFilters>
        </div>
        <div v-if="componentData && componentData.table_field_group_by">
          <el-table
            @header-dragend="headerDragged"
            :data="groupedData"
            :total="total"
            :current-page.sync="currentPage"
            :page-size="pageSize"
            :pagination-props="paginationProps"
            :page-sizes="paginationProps"
            @query-change="loadData"
            style="width: 100%"
            class="vue-data-table-default"
            border
            stripe
            @sort-change="sortChange"
          >
            <el-table-column
              :label="getLabel(componentData.table_field_group_by)"
              sortable="custom"
              :prop="'entity_label'"
              :width="
                getCustomDashboardTableWidth &&
                getCustomDashboardTableWidth['entity_label']
                  ? getCustomDashboardTableWidth['entity_label']
                  : 250
              "
            >
              <template slot-scope="scope">
                <div v-if="scope.row['entity_label']">
                  {{ scope.row["entity_label"] }}
                </div>
                <div v-else>{{ scope.row["_id"] }}</div>
              </template>
            </el-table-column>
            <el-table-column
              v-for="(
                step, index
              ) of componentData.table_field_group_by_fields.filter(
                (e) => !e.sub_group
              )"
              :label="step.name"
              :key="step.key + index"
              sortable="custom"
              :prop="step.key"
              :width="
                getCustomDashboardTableWidth &&
                getCustomDashboardTableWidth[step.key]
                  ? getCustomDashboardTableWidth[step.key]
                  : 250
              "
            >
              <template slot-scope="scope">
                <div>
                  <el-link
                    :underline="false"
                    type="primary"
                    @click="openCountDialog(scope.row, null)"
                  >
                    {{ getCurrencyFormat(scope.row[step.key], step.key) }}
                  </el-link>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              v-for="(step, index) of custom_columns"
              :key="index + '_' + step.value"
              :label="step.label"
              :prop="step.value"
              :width="
                getCustomDashboardTableWidth &&
                getCustomDashboardTableWidth[step.value]
                  ? getCustomDashboardTableWidth[step.value]
                  : 250
              "
            >
              <template slot-scope="scope">
                <div>
                  <el-link
                    :underline="false"
                    type="primary"
                    @click="openCountDialog(scope.row, step.value)"
                    >{{ getSubField(scope.row, step.value) }}</el-link
                  >
                </div>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
            :class="'custom-pagination'"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page.sync="currentPage"
            :page-sizes="paginationProps"
            :page-size="pageSize"
            :pager-count="5"
            layout="prev, pager, next, jumper,sizes, total"
            :total="total"
            small
          ></el-pagination>
        </div>
        <div v-else>
          <el-table
            :data="data"
            @header-dragend="headerDragged"
            :total="total"
            :current-page.sync="currentPage"
            :page-size="pageSize"
            :pagination-props="paginationProps"
            :page-sizes="paginationProps"
            @query-change="loadData"
            @selection-change="handleSelectionChange"
            style="width: 100%"
            class="vue-data-table-default"
            border
            stripe
            @sort-change="sortChange"
          >
            <el-table-column
              v-if="checkAccessForActions"
              type="selection"
              width="30"
              fixed
            >
            </el-table-column>
            <el-table-column
              :width="
                getCustomDashboardTableWidth &&
                getCustomDashboardTableWidth[step.template_id + '#' + step.id]
                  ? getCustomDashboardTableWidth[
                      step.template_id + '#' + step.id
                    ]
                  : step.width
                  ? step.width
                  : 250
              "
              v-for="(step, index) of tableColums"
              :label="step.label"
              :key="step.id + index"
              :prop="getPropValue(step)"
              sortable="custom"
            >
              <template slot-scope="scope">
                <div class="d-flex" :style="getFieldStyle(step, scope.row)">
                  <div v-if="step && step.type === 'PAY_BUTTON'">
                    {{ getFieldValue(scope.row, step) }}
                  </div>
                  <div
                    v-else-if="
                      step &&
                      step.type === 'DATE' &&
                      (step.id === 'created_at' || step.id === 'updated_at')
                    "
                  >
                    {{ scope.row[step.id] }}
                  </div>
                  <!-- <div v-else-if="step && step.type === 'SELECT'" >
                {{ scope.row.entityData[scope.row.templates_data[0].template_id].status }}
                </div> -->
                  <div
                    class="pl-15"
                    v-else-if="
                      scope.row.entityData &&
                      scope.row.entityData[step.template_id] &&
                      scope.row.entityData[step.template_id][
                        `${step.id}_code`
                      ] &&
                      scope.row.entityData[step.template_id][`${step.id}_code`]
                        .formatInternational
                    "
                    @click="viewWorkUserData(scope.row)"
                  >
                    {{
                      scope.row.entityData[step.template_id][`${step.id}_code`]
                        .formatInternational
                    }}
                  </div>
                  <div
                    class="pl-15"
                    v-else-if="
                      step.input_type == 'PAYMENT_VARIABLE' &&
                      step.type == 'CURRENCY'
                    "
                  >
                    <span v-if="getPayVariableCurrency(step) === 'USD'">
                      {{
                        Intl.NumberFormat("en-US", {
                          style: "currency",
                          currency: "USD",
                        }).format(
                          getCurrencyValues(
                            scope.row.entityData[step.template_id][step.id]
                          )
                        )
                      }}
                    </span>
                    <span v-else-if="getPayVariableCurrency(step) === 'INR'">
                      {{
                        Intl.NumberFormat("en-IN", {
                          style: "currency",
                          currency: "INR",
                        }).format(
                          getCurrencyValues(
                            scope.row.entityData[step.template_id][step.id]
                          )
                        )
                      }}
                    </span>
                    <span v-else-if="getPayVariableCurrency(step) === 'EUR'">
                      {{
                        Intl.NumberFormat("en-IE", {
                          style: "currency",
                          currency: "EUR",
                        }).format(
                          parseFloat(
                            getCurrencyValues(
                              scope.row.entityData[step.template_id][step.id]
                            )
                          ).toFixed(2)
                        )
                      }}
                    </span>
                    <span v-else-if="getPayVariableCurrency(step) === 'CAD'">
                      {{
                        Intl.NumberFormat("en-US", {
                          style: "currency",
                          currency: "CAD",
                        }).format(
                          getCurrencyValues(
                            scope.row.entityData[step.template_id][step.id]
                          )
                        )
                      }}
                    </span>
                    <span v-else-if="getPayVariableCurrency(step) === 'AUD'">
                      {{
                        Intl.NumberFormat("en-US", {
                          style: "currency",
                          currency: "AUD",
                        }).format(
                          getCurrencyValues(
                            scope.row.entityData[step.template_id][step.id]
                          )
                        )
                      }}
                    </span>
                  </div>

                  <div
                    class="pl-15"
                    v-else-if="
                      scope.row.entityData &&
                      scope.row.entityData[step.template_id] &&
                      scope.row.entityData[step.template_id][
                        `${step.id}_currency`
                      ] &&
                      scope.row.entityData[step.template_id][
                        `${step.id}_currency`
                      ]
                    "
                    @click="viewWorkUserData(scope.row)"
                  >
                    <span
                      v-if="
                        scope.row.entityData[step.template_id][
                          `${step.id}_currency`
                        ] === 'USD'
                      "
                    >
                      {{
                        Intl.NumberFormat("en-US", {
                          style: "currency",
                          currency: "USD",
                        }).format(
                          getCurrencyValues(
                            scope.row.entityData[step.template_id][step.id]
                          )
                        )
                      }}
                    </span>
                    <span
                      v-else-if="
                        scope.row.entityData[step.template_id][
                          `${step.id}_currency`
                        ] === 'INR'
                      "
                    >
                      {{
                        Intl.NumberFormat("en-IN", {
                          style: "currency",
                          currency: "INR",
                        }).format(
                          getCurrencyValues(
                            scope.row.entityData[step.template_id][step.id]
                          )
                        )
                      }}
                    </span>
                    <span
                      v-else-if="
                        scope.row.entityData[step.template_id][
                          `${step.id}_currency`
                        ] === 'EUR'
                      "
                    >
                      {{
                        Intl.NumberFormat("en-IE", {
                          style: "currency",
                          currency: "EUR",
                        }).format(
                          parseFloat(
                            getCurrencyValues(
                              scope.row.entityData[step.template_id][step.id]
                            )
                          ).toFixed(2)
                        )
                      }}
                    </span>
                    <span
                      v-else-if="
                        scope.row.entityData[step.template_id][
                          `${step.id}_currency`
                        ] === 'CAD'
                      "
                    >
                      {{
                        Intl.NumberFormat("en-US", {
                          style: "currency",
                          currency: "CAD",
                        }).format(
                          getCurrencyValues(
                            scope.row.entityData[step.template_id][step.id]
                          )
                        )
                      }}
                    </span>
                    <span
                      v-else-if="
                        scope.row.entityData[step.template_id][
                          `${step.id}_currency`
                        ] === 'AUD'
                      "
                    >
                      {{
                        Intl.NumberFormat("en-US", {
                          style: "currency",
                          currency: "AUD",
                        }).format(
                          getCurrencyValues(
                            scope.row.entityData[step.template_id][step.id]
                          )
                        )
                      }}
                    </span>
                  </div>
                  <div v-else-if="step && step.type == 'MULTI_LINE_TEXT'">
                    <span v-if="getFieldValue(scope.row, step)">
                      <span v-if="getFieldValue(scope.row, step).length > 100">
                        {{ truncateText(getFieldValue(scope.row, step)) }}
                        <a
                          href="#"
                          @click.prevent="
                            openMultiLineData(
                              getFieldValue(scope.row, step),
                              step.label,
                              step
                            )
                          "
                          style="color: #409eff"
                          >Read more</a
                        >
                      </span>
                      <span v-else>
                        {{ getFieldValue(scope.row, step) }}
                      </span>
                    </span>
                  </div>

                  <div v-else-if="step && step.type == 'DATE'">
                    {{ getFieldValue(scope.row, step) | globalDateFormat }}
                  </div>
                  <div
                    v-else-if="step && step.type == 'DATE_TIME'"
                    @click="viewWorkUserData(scope.row)"
                  >
                    {{
                      getFieldValue(scope.row, step)
                        | moment("MM-DD-YYYY hh:mm:ss A")
                    }}
                  </div>
                  <div v-else-if="step && step.type == 'FILE'">
                    <div
                      v-if="
                        getFieldValue(scope.row, step) &&
                        getFieldValue(scope.row, step).length &&
                        getFieldValue(scope.row, step).length <= 1
                      "
                    >
                      <el-button
                        type="text"
                        style="margin-top: -14px"
                        @click="downloadFile(getFieldValue(scope.row, step)[0])"
                        class="mb-1"
                      >
                        <p style="font-size: 13px">
                          {{
                            getFieldValue(scope.row, step)[0].name
                              | truncate(20, "...")
                          }}
                          <i class="el-icon-download"></i>
                        </p>
                      </el-button>
                    </div>
                    <div
                      class="d-flex"
                      v-else-if="
                        getFieldValue(scope.row, step) &&
                        getFieldValue(scope.row, step).length &&
                        getFieldValue(scope.row, step).length >= 1
                      "
                    >
                      <p
                        v-for="(file, index) of getFieldValue(scope.row, step)"
                        :key="index"
                      >
                        <el-button
                          type="text"
                          style="margin-top: -35px; margin-bottom: -10%"
                          v-if="file && file.name && index < limit"
                          @click="downloadFile(file)"
                        >
                          <p style="font-size: 13px">
                            {{ file.name | truncate(10, "...") }}
                            <i class="el-icon-download"></i>
                          </p>
                        </el-button>
                        <br />
                      </p>
                      <el-tag
                        style="cursor: pointer; width: 40px"
                        class="ml-1"
                        :value="getFieldValue(scope.row, step).length"
                        v-if="getFieldValue(scope.row, step).length > 1"
                        @click="downloadDocumentsPopup(scope.row, step)"
                      >
                        +{{ getFieldValue(scope.row, step).length - 1 }}
                      </el-tag>
                    </div>
                    <!-- </p> -->
                  </div>
                  <div
                    v-else-if="
                      step &&
                      step.type == 'DATE_TIME_RANGE' &&
                      scope.row.entityData[step.template_id] &&
                      scope.row.entityData[step.template_id][`${step.id}_from`]
                    "
                    @click="viewWorkUserData(scope.row)"
                  >
                    {{
                      scope.row.entityData[step.template_id][`${step.id}_from`]
                        | moment("MM-DD-YYYY hh:mm:ss A")
                    }}
                    (From ~ To)
                    {{
                      scope.row.entityData[step.template_id][`${step.id}_to`]
                        | moment("MM-DD-YYYY hh:mm:ss A")
                    }}
                  </div>
                  <div v-else-if="step && step.type == 'TIME_RANGE'">
                    <p style="font-size: 13px">
                      {{ getFieldValue(scope.row, step) | toTimeRange }}
                    </p>
                  </div>
                  <div
                    v-else-if="step && step.type == 'TIME'"
                    @click="viewWorkUserData(scope.row)"
                  >
                    {{ getFieldValue(scope.row, step) | moment("hh:mm") }}
                  </div>
                  <div
                    v-else-if="
                      typeof getFieldValue(scope.row, step) == 'object' &&
                      getFieldValue(scope.row, step).lengths
                    "
                    @click="viewWorkUserData(scope.row)"
                  >
                    {{ getFieldValue(scope.row, step).join(",") }}
                  </div>
                  <div v-else-if="step && step.type == 'SIGNATURE'">
                    <img
                      v-if="getFieldValue(scope.row, step)"
                      :src="getFieldValue(scope.row, step)"
                      alt="IMG"
                      width="100"
                      height="60"
                    />
                    <p v-else>-</p>
                  </div>
                  <div v-else-if="step && step.type == 'AGGREGATE_FUNCTION'">
                    <p style="font-size: 13px">
                      {{
                        getCurrencyFormat(
                          getFieldValue(scope.row, step),
                          step.template_id + "#" + step.id
                        )
                      }}
                    </p>
                  </div>
                  <div
                    v-else-if="
                      step &&
                      step.type == 'DATE_RANGE' &&
                      scope.row.entityData[step.template_id] &&
                      scope.row.entityData[step.template_id][step.id]
                    "
                  >
                    <p style="font-size: 13px">
                      <!-- {{getFieldValue(scope.row, step)}}<br /> -->
                      {{ getFieldValue(scope.row, step) | toDateRange }}
                      <!-- | toDateRange -->
                    </p>
                  </div>
                  <div v-else-if="step && step.type == 'FORMULA'">
                    <p
                      style="font-size: 13px"
                      v-if="
                        (getFieldValue(scope.row, step) ||
                          typeof getFieldValue(scope.row, step) == 'number') &&
                        step.currency_code &&
                        step.currency_code.currency
                      "
                    >
                      {{
                        Intl.NumberFormat(getCurrencyFormet(step).code, {
                          style: "currency",
                          currency: getCurrencyFormet(step).currency,
                        }).format(getFieldValue(scope.row, step))
                      }}
                    </p>
                    <p v-else-if="isDate(getFieldValue(scope.row, step))">
                      {{ getFieldValue(scope.row, step) | globalDateFormat }}
                    </p>
                    <p v-else>
                      {{
                        getCurrencyFormat(
                          getFieldValue(scope.row, step),
                          step.template_id + "#" + step.id
                        )
                      }}
                    </p>
                  </div>
                  <div v-else-if="step.type == 'MULTI_LINE_TEXT'">
                    <span
                      v-if="
                        step &&
                        step.type == 'MULTI_LINE_TEXT' &&
                        step.currency_code.data_type
                      "
                    >
                      <span v-if="getFieldValue(scope.row, step)">
                        <span
                          v-if="getFieldValue(scope.row, step).length > 100"
                        >
                          {{
                            truncateText(
                              getPlainText(getFieldValue(scope.row, step))
                            )
                          }}
                          <a
                            href="#"
                            @click.prevent="
                              openMultiLineData(
                                getFieldValue(scope.row, step),
                                step.label
                              )
                            "
                            style="color: #409eff"
                            >Read more</a
                          >
                        </span>
                        <span v-else>
                          {{ getPlainText(getFieldValue(scope.row, step)) }}
                        </span>
                      </span>
                    </span>
                    <span v-else>
                      <span v-if="getFieldValue(scope.row, step)">
                        <span
                          v-if="getFieldValue(scope.row, step).length > 100"
                        >
                          {{
                            truncateText(
                              getPlainText(getFieldValue(scope.row, step))
                            )
                          }}
                          <a
                            href="#"
                            @click.prevent="
                              openMultiLineData(
                                getFieldValue(scope.row, step),
                                step.label,
                                step
                              )
                            "
                            style="color: #409eff"
                            >Read more</a
                          >
                        </span>
                        <span v-else>
                          {{ getPlainText(getFieldValue(scope.row, step)) }}
                        </span>
                      </span>
                    </span>
                  </div>
                  <div v-else-if="step && step.type == 'SINGLE_LINE_TEXT'">
                    <p v-if="data.input_type == 'MASKED'">xxxx</p>
                    <p
                      v-else-if="
                        step &&
                        step.currency_code &&
                        step.currency_code.type == 'HYPER_LINK'
                      "
                    >
                      <el-link
                        @click="checkNavigationAction(scope.row, step)"
                        style="color: rgb(69 188 237)"
                        >{{ getFieldValue(scope.row, step) }}</el-link
                      >
                    </p>
                    <div v-else style="font-size: 13px">
                      <div>
                        <p>{{ getFieldValue(scope.row, step) }}</p>
                      </div>
                    </div>
                  </div>

                  <div
                    class="pl-15"
                    v-else
                    @click="viewWorkUserData(scope.row)"
                  >
                    {{ getFieldValue(scope.row, step) }}
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              prop="action"
              label="Actions"
              class="action"
              min-width="170"
              fixed="right"
              v-if="
                (checkPerimission('VIEW') ||
                  checkPerimission('EDIT') ||
                  checkPerimission('DELETE') ||
                  checkPerimission('SEND NOTIFICATION')) &&
                !hideAllActions
              "
            >
              <template slot-scope="scope">
                <ul class="action-buttons">
                  <li
                    v-if="
                      checkPerimission('VIEW') &&
                      IframePermissions('VIEW', 'ViewEntityData')
                    "
                  >
                    <template
                      v-if="
                        customization &&
                        customizationButtons &&
                        customizationButtons.length
                      "
                    >
                      <button
                        class="btn btn-light btn-sm m-lr-1"
                        type="button"
                        v-for="button in getButtonsList(customizationButtons)"
                        :key="button"
                      >
                        <span
                          v-if="button === 'Edit'"
                          class="text-muted"
                          title="Edit"
                          @click="editWorkUserData(scope.row)"
                          ><i class="fa-solid fa-pen"></i
                        ></span>
                        <span
                          v-else-if="button === 'View'"
                          class="text-muted"
                          title="View"
                          @click="viewWorkUserData(scope.row)"
                          ><i class="fa fa-eye"></i
                        ></span>
                        <span
                          v-else-if="button === 'Delete'"
                          class="text-muted"
                          title="Delete"
                          @click="onEntityDataDelete(scope.row)"
                          ><i class="fa-solid fa-trash"></i
                        ></span>
                        <span
                          v-else-if="button === 'Send Notification'"
                          class="text-muted"
                          title="Send Notification"
                          @click="openSendNotification(scope.row)"
                          ><i class="fa-solid fa-share"></i
                        ></span>
                      </button>
                    </template>
                    <template v-else>
                      <button
                        class="btn btn-light btn-sm m-lr-1"
                        type="button"
                        @click="viewWorkUserData(scope.row)"
                      >
                        <span class="text-muted" title="View">
                          <i class="fa fa-eye"></i>
                        </span>
                      </button>
                    </template>
                  </li>
                  <li
                    v-if="
                      ((checkPerimission('EDIT') &&
                        currentEntity &&
                        currentEntity.entity_type != 'INDIVIDUAL') ||
                        checkPerimission('EDIT') ||
                        checkPerimission('SEND NOTIFICATION') ||
                        checkPerimission('VIEW') ||
                        checkPerimission('DELETE')) &&
                      (IframePermissions('EDIT', 'ViewEntityData') ||
                        IframePermissions(
                          'SEND NOTIFICATION',
                          'ViewEntityData'
                        ) ||
                        IframePermissions('DELETE', 'ViewEntityData'))
                    "
                  >
                    <el-dropdown>
                      <span class="el-dropdown-link">
                        <i
                          class="el-icon-more"
                          style="transform: rotate(90deg)"
                        ></i>
                      </span>
                      <el-dropdown-menu slot="dropdown">
                        <a
                          :underline="false"
                          @click="duplicateEntityData(scope.row)"
                          v-if="
                            checkPerimission('EDIT') &&
                            currentEntity &&
                            currentEntity.entity_type != 'INDIVIDUAL'
                          "
                        >
                          <el-dropdown-item
                            icon="el-icon-document-copy"
                            v-if="checkButtonVisible('Duplicate')"
                            >Duplicate</el-dropdown-item
                          >
                        </a>
                        <a
                          :underline="false"
                          @click="editWorkUserData(scope.row)"
                          v-if="
                            checkPerimission('EDIT') &&
                            IframePermissions('EDIT', 'ViewEntityData') &&
                            !(
                              customizationButtons &&
                              customizationButtons.some(
                                (button) => button === 'Edit'
                              )
                            )
                          "
                        >
                          <el-dropdown-item icon="el-icon-edit"
                            >Edit</el-dropdown-item
                          >
                        </a>
                        <a
                          :underline="false"
                          @click="viewWorkUserData(scope.row)"
                          v-if="
                            checkPerimission('VIEW') &&
                            customization != null &&
                            !(
                              customizationButtons &&
                              customizationButtons.some(
                                (button) => button === 'View'
                              )
                            )
                          "
                        >
                          <el-dropdown-item icon="el-icon-view"
                            >View</el-dropdown-item
                          >
                        </a>
                        <a
                          :underline="false"
                          @click="openSendNotification(scope.row)"
                          v-if="
                            checkPerimission('SEND NOTIFICATION') &&
                            checkButtonVisible('Send Notification') &&
                            IframePermissions(
                              'SEND NOTIFICATION',
                              'ViewEntityData'
                            ) &&
                            !(
                              customizationButtons &&
                              customizationButtons.some(
                                (button) => button === 'Send Notification'
                              )
                            )
                          "
                        >
                          <el-dropdown-item icon="el-icon-s-promotion"
                            >Send Notification</el-dropdown-item
                          >
                        </a>
                        <a
                          @click="onEntityDataDelete(scope.row)"
                          :underline="false"
                          v-if="
                            checkPerimission('DELETE') &&
                            IframePermissions('DELETE', 'ViewEntityData') &&
                            !(
                              customizationButtons &&
                              customizationButtons.some(
                                (button) => button === 'Delete'
                              )
                            )
                          "
                        >
                          <el-dropdown-item icon="el-icon-delete"
                            >Delete</el-dropdown-item
                          >
                        </a>
                        <div
                          v-if="
                            customization != null &&
                            customization.actions &&
                            customization.actions.length &&
                            getIframeUrlInfo == null
                          "
                        >
                          <a
                            v-for="(
                              action, index
                            ) in customization.actions.filter((e) => e.active)"
                            @click="onAction(action, scope.row)"
                            :key="index"
                            :underline="false"
                          >
                            <el-dropdown-item :icon="fetchActionIcon(action)">{{
                              action.label
                            }}</el-dropdown-item>
                          </a>
                        </div>
                      </el-dropdown-menu>
                    </el-dropdown>
                  </li>
                </ul>
              </template>
            </el-table-column>
          </el-table>
          <div class="pagination-style">
            <el-pagination
              :class="'custom-pagination'"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page.sync="currentPage"
              :page-sizes="paginationProps"
              :page-size="pageSize"
              :pager-count="5"
              layout="prev, pager, next, jumper,sizes, total"
              :total="total"
              small
            ></el-pagination>
          </div>
        </div>
      </el-col>
    </el-row>
    <dialog-component
      :width="getIsMobile ? '100%' : '30%'"
      :visible="openDialogVisible"
      :title="'Edit Component'"
      @before-close="openDialogVisible = false"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <div v-loading="loading">
        <CustomComponentEdit
          :addStatsData="updateComponentData"
          :getRelationshipEntities="getRelationshipEntities"
          :selectedEntity="currentEntity"
          :globalVariablesData="globalVariablesData"
          :permittedEntities="permittedEntities"
          :getAllEntities="getAllEntities"
          :getAllCompanyTemplatesData="getAllCompanyTemplatesData"
        ></CustomComponentEdit>
      </div>
      <span slot="footer" class="dialog-footer">
        <button
          class="btn btn-outline-danger btn-sm m-lr-1"
          type="button"
          @click="openDialogVisible = false"
        >
          <i class="fa fa-close"></i> Cancel
        </button>
        <button
          class="btn btn-outline-primary btn-sm m-lr-1"
          type="button"
          @click="updateComponent"
        >
          <i class="fa fa-sign-out"></i> Save
        </button>
      </span>
    </dialog-component>
    <dialog-component
      :title="multiLineTextLabel"
      :visible="openMultiLineTextDialog"
      @before-close="closeMultiLineData"
      :isShowFooter="false"
      :containerMaxWidth="'50vw'"
    >
      <div style="padding: 20px" class="desription-text" v-if="isRichText">
        <!-- <ckeditor :config="editorConfig" v-model="multiLineTextData">
      </ckeditor> -->
        <div v-html="multiLineTextData"></div>
      </div>
      <div class="desription-text" v-else>
        {{ multiLineTextData | truncateHTML() }}
      </div>
    </dialog-component>

    <el-dialog
      :width="getIsMobile ? '100%' : '90%'"
      heigth="200px;"
      :visible.sync="openFilterModal"
      title="Apply filters"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <div>
        <div
          style="display: flex"
          v-if="
            currentFilter &&
            currentFilter.quick_filters &&
            currentFilter.quick_filters.length
          "
        >
          <h3>Quick filters</h3>
          <br />
          <QuickEntityFilters
            class="ml-1"
            :filterFields="currentFilter.quick_filters"
            :key="
              componentData.entity_id +
              '_' +
              index +
              '_' +
              componentData.filter_id +
              '_table_filters_1'
            "
            :allFields="selectedEntityFields"
            :selectedFileds="selectedFileds"
          ></QuickEntityFilters>
        </div>
        <div v-else style="text-align: center">
          There is no quick filters attached for this component
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <button
          class="btn btn btn-outline-danger btn-sm m-lr-1"
          @click="openFilterModal = false"
        >
          <i class="fa fa-close"></i> Cancel
        </button>
        <button
          class="btn btn btn-outline-primary btn-sm m-lr-1"
          type="button"
          @click="applyQuickFilter"
          v-if="
            currentFilter &&
            currentFilter.quick_filters &&
            currentFilter.quick_filters.length
          "
        >
          <i class="fa fa-check-square-o"></i> Apply filter
        </button>
      </span>
    </el-dialog>
    <dialog-component
      :visible="showCountDialog"
      :title="componentData.component_name"
      :containerWidth="
        getIsMobile
          ? '100%'
          : tableColums.length > 4
          ? '90%'
          : tableColums.length * 19 + '%'
      "
      @before-close="showCountDialog = false"
    >
      <CustomComponentTable
        :component="countComponent"
        :entity_id="this.componentData.entity_id"
        :newFilters="countComponentFilters"
        :customFilters="[]"
        :hide_options="true"
        :hide_expend="true"
        :isFromApplicationuser="isApplicationUser"
        :hasViewPermission="hasViewPermission"
        :hasEditPermission="hasEditPermission"
        :hasDuplicatePermission="hasDuplicatePermission"
        :hasDeletePermission="hasDeletePermission"
        :searchString="search_string"
        :permittedEntities="permittedEntities"
        :getAllEntities="getAllEntities"
        v-if="showCountDialog"
      ></CustomComponentTable>
    </dialog-component>
    <dialog-component
      :modal="false"
      :visible="downloadDocuments"
      :title="'Download Documents'"
      @before-close="closeDownloadDocuments"
      :containerWidth="'40%'"
    >
      <el-row>
        <el-input
          class="search mb-10 mt-10 seachWidth float-left mr-4"
          placeholder="Search"
          v-model="searchDocument"
          clearable
          @input="searchDocumentName"
          prefix-icon="el-icon-search"
        ></el-input>
      </el-row>
      <!-- <el-row>
        <el-input
          class="search mb-1"
          placeholder="Search"
          v-model="searchDocument"
          clearable
          @input="searchDocumentName"
          prefix-icon="el-icon-search"
        ></el-input>
      </el-row> -->

      <div class="vue-data-table-default m-tb-3 scrollable-table-container">
        <el-scrollbar class="scrollbar-container">
          <el-table
            border
            :data="searchDocument ? filteredDocuments : documentsData"
            :header-row-class-name="'my-header-row'"
            class="tableSty"
            :show-header="true"
            size="mini"
          >
            <el-table-column class="labelSty" label="Document Name">
              <template slot-scope="scope">
                <p style="font-size: 14px" slot="reference">
                  {{ scope.row.name }}
                </p>
              </template>
            </el-table-column>
            <el-table-column label="Action" width="150">
              <template slot-scope="scope">
                <el-button
                  @click="onDownloadFile(scope.row)"
                  target="_blank"
                  ref="downloadLink"
                  style="width: 120px; font-size: 12px"
                >
                  Download
                  <i class="el-icon-download"></i>
                </el-button>
              </template>
            </el-table-column>
          </el-table>
          <p
            v-if="searchDocument && filteredDocuments.length === 0"
            class="ml-3 m-lr-1 m-tb-1"
          >
            No documents found.
          </p>
          <p
            v-else-if="!searchDocument && documentsData.length === 0"
            class="ml-3 m-lr-1 m-tb-1"
          >
            No documents found.
          </p>
        </el-scrollbar>
      </div>

      <span slot="footer">
        <button
          class="btn btn btn-outline-danger btn-sm m-lr-1"
          @click="downloadDocuments = false"
        >
          <i class="fa fa-close"></i> Close
        </button>
      </span>
    </dialog-component>
    <dialog-component
      :title="'Send Notification'"
      :visible="sendNotificationDialog"
      v-loading="loading"
      :containerWidth="getIsMobile ? '100%' : '52%'"
      @before-close="sendNotificationDialog = false"
      :isShowFooter="false"
    >
      <EmailConfig
        :currentEntity="currentEntity"
        :isSendIndividual="true"
        :entityData="selectedNotifyEntity"
        :paramsContent="paramsData"
        v-on:closeNotificationDialog="closeSendNotificationDialog"
      >
      </EmailConfig>
    </dialog-component>
    <dialog-component
      :title="'Bulk Notification (selected data)'"
      :visible="bulkSendNotificationDialog"
      v-loading="loading"
      :containerWidth="getIsMobile ? '100%' : '52%'"
      @before-close="bulkSendNotificationDialog = false"
      :isShowFooter="false"
    >
      <EmailConfig
        :currentEntity="currentEntity"
        :isSendIndividual="false"
        :selectedIds="selectedIds"
        :paramsContent="paramsData"
        v-on:closeNotificationDialog="closeBulkSendNotificationDialog"
      >
      </EmailConfig>
    </dialog-component>

    <dialog-component
      :title="multiLineTextLabel"
      :visible="openMultiLineTextDialog"
      @before-close="closeMultiLineData"
      :isShowFooter="false"
      :containerMaxWidth="'50vw'"
    >
      <div class="desription-text" v-if="isRichText">
        <ckeditor :config="editorConfig" v-model="multiLineTextData">
        </ckeditor>
      </div>
      <div class="desription-text" v-else>
        {{ multiLineTextData | truncateHTML() }}
      </div>
    </dialog-component>
    <div v-if="quickActionModal && quickActionDummyField">
      <ActionButtonExecute
        :data="quickActionDummyField"
        :isView="false"
        :entityId="currentEntity._id"
        :entityDataId="quickActionDummyField.parent_entityDataId"
        :fromQuickAction="true"
        @closeModal="quickActionModal = false"
      >
      </ActionButtonExecute>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { bus } from "../../main";
import CustomDashboardHelper from "@/mixins/CustomDashboardHelper.js";
import NavigationHelper from "@/mixins/navigationHelper";
import EntityHelper from "@/mixins/EntitiesHelper";
import TemplateBuilderhelper from "@/mixins/templateBuilderHelper.js";
// import moment from "moment";
import DocumentsHelper from "@/mixins/CompanyDocumentsHelper";
import fieldsDataHelper from "@/mixins/fieldsDataHelper.js";
import * as XLSX from "xlsx";
import { fetchFilterById } from "@/repo/filtersRepo";

import { postAPICall } from "@/helpers/httpHelper";
export default {
  mixins: [
    CustomDashboardHelper,
    NavigationHelper,
    EntityHelper,
    DocumentsHelper,
    TemplateBuilderhelper,
    fieldsDataHelper,
  ],
  watch: {
    getGlobalFiltervalue: {
      handler() {
        if (this.checkGlobalFilter?.apply_on) {
          this.fetchEntitiesDataForTable();
        }
      },
    },
  },
  data() {
    return {
      multiLineTextData: "",
      multiLineTextLabel: "",
      openMultiLineTextDialog: false,
      isRichText: false,
      filters: [],
      modalLoading: false,
      loading: false,
      openDialogVisible: false,
      searchDocument: "",
      tableColums: [],
      currentEntity: null,
      currentFilter: null,
      constantFilter: null,
      total: 0,
      currentTotal: 0,
      currentPage: 1,
      currentGroupPage: 1,
      pageSize: 0,
      pageGroupSize: 0,
      data: [],
      pageSizes: [5, 10, 15],
      componentData: {},
      updateComponentData: {},
      loadingText: "Loading ",
      selectedEntityFields: [],
      openFilterModal: false,
      selectedFileds: {},
      selected_date_picker: "",
      pickerOptions: [
        {
          label: "Today",
          value: "today",
        },
        {
          label: "Yesterday",
          value: "yesterday",
        },
        {
          label: "Last 7 Days",
          value: "last_7_days",
        },
        {
          label: "Last week",
          value: "last_week",
        },
        {
          label: "This week",
          value: "this_week",
        },
        {
          label: "Next week",
          value: "next_week",
        },
        {
          label: "Last month",
          value: "last_month",
        },
        {
          label: "This month",
          value: "this_month",
        },
        {
          label: "Next month",
          value: "next_month",
        },
        {
          label: "Last quarter",
          value: "last_quarter",
        },
        {
          label: "This quarter",
          value: "this_quarter",
        },
        {
          label: "Next quarter",
          value: "next_quarter",
        },
        {
          label: "Last year",
          value: "last_year",
        },
        {
          label: "This year",
          value: "this_year",
        },
        {
          label: "Next year",
          value: "next_year",
        },
        // {
        //   label: "Custom date",
        //   value: "custom_date"
        // },
        // {
        //   label: "Custom date range",
        //   value: "custom_date_range"
        // },
      ],
      sortBy: "_id",
      sortOrder: -1,
      applied_quick_filters: [],
      groupedData: [],
      showCountDialog: false,
      countComponent: null,
      countComponentFilters: [],
      search_string: "",
      custom_columns: [],
      weekDayMap: {
        MON: "Monday",
        TUE: "Tuesday",
        WED: "Wednesday",
        THU: "Thursday",
        FRI: "Friday",
        SAT: "Saturday",
        SUN: "Sunday",
      },
      isEdit: false,
      exportFilters: [],
      applied_filters: [],
      limit: 1,
      downloadDocuments: false,
      documentsData: [],
      selectedRowsIds: [],
      selectedNotifyEntity: null,
      sendNotificationDialog: false,
      paramsData: {},
      bulkSendNotificationDialog: false,
      selectedIds: [],
      entityPermission: null,
      customization: null,
      quickActionDummyField: {},
      quickActionModal: false,
      applicationUserConfigure: false,
      quickActionIcons: {
        add: "el-icon-plus",
        assign: "el-icon-check",
        view: "el-icon-view",
        add_assign: "el-icon-top-right",
      },
      rowEntityData: {},
    };
  },
  components: {
    CustomComponentEdit: () => import("./customComponentEdit.vue"),
    QuickEntityFilters: () =>
      import("@/components/widgets/entityQuickFilters.vue"),
    CustomComponentTable: () => import("./customDashboardTable.vue"),
    EmailConfig: () => import("../notifications/emailConfig.vue"),
    ActionButtonExecute: () =>
      import(
        "@/components/templates/formComponentsExecute/ActionButtonExecute"
      ),
  },
  computed: {
    getCurrencyValues() {
      return (value) => {
        return value ? value : 0;
      };
    },
    getPayVariableCurrency() {
      return (scope) => {
        return scope && scope.currency_code && scope.currency_code.currency
          ? scope.currency_code.currency
          : "USD";
      };
    },

    checkAccessForActions() {
      //Send Notification As Of Now
      return this.component.send_notification;
    },
    getMainLabel() {
      if (this.componentData && this.componentData.table_field_group_by) {
        return this.getLabel(this.componentData.table_field_group_by);
      }
      return "";
    },
    isApplicationUser() {
      return (
        this.$route.name == "entity-custom-dashboard-config" ||
        this.$route.name == "application-user-dashboard" ||
        this.$route.name == "application-user-page-dashboard"
      );
    },
    getComponentName() {
      if (this.componentData && this.componentData.component_name) {
        return this.componentData.component_name;
      } else {
        let name = "";
        if (this.currentEntity && this.currentEntity.name) {
          name = this.currentEntity.name;
        }
        if (this.currentFilter && this.currentFilter.filter_name) {
          name = name + " - " + this.currentFilter.filter_name;
        }
        return name;
      }
    },
    paginationProps() {
      return [5, 10, 20, 50, 100];
    },
    paginationPropsForTable() {
      return {
        pageSizes: this.pageSizes || [10, 20, 50],
      };
    },
    getAllEntitiesData() {
      return this.getAllEntities.data || [];
    },
    checkTableDisabled() {
      return (
        !this.updateComponentData.component_name ||
        !this.updateComponentData.entity_id
        // !this.updateComponentData.filter_id
      );
    },
    getPlaceholderName() {
      if (this.componentData.date_filter_field) {
        let field = this.selectedEntityFields.find(
          (e) => e.key === this.componentData.date_filter_field
        );
        if (field && field.label) {
          return `By ${field.label}`;
        }
        return "Pick a date";
      }
      return "Pick a date";
    },
    shouldShowButton() {
      if (this.componentData.type === 'TABLE') {
        return this.componentData.ShowExport;
      }
      return true;
    },
    ...mapGetters("entities", [
      "getEntityCreatedStatus",
      "getEntityCreatedData",

      "getEntityDataById",
      "getEntityDataUpdateStatus",
      "getEntityErrors",
      "getEntityRecordsForTable",
      "getSentEntityNotificationStatus",
      "getSentEntityDataNotificationStatus",
      "getSentEntityNotificationStatusErrors",
    ]),
    ...mapGetters("filters", ["getAllEntityFilters"]),
    ...mapGetters("navigationOpen", ["getIsMobile", "getIsRSOpen"]),
    ...mapGetters("templatesData", ["getDownloadUrl", "getDownloadError"]),
    ...mapGetters("s3FileUpload", [
      "getFileUploadData",
      "getCompanyLogoUpdateData",
      "getFileUploadStatus",
    ]),
    ...mapGetters("iFrame", ["getIframeUrlInfo"]),
    ...mapGetters("menuManagementV2", ["getMenu"]),
    ...mapGetters("customDashboard", ["getCustomDashboardTableWidth"]),
    // ...mapGetters("auth", ["getGlobalFiltervalue"]),
  },
  props: {
    searchString: String,
    component: Object,
    entity_id: String,
    filter_id: String,
    hide_options: Boolean,
    hide_expend: Boolean,
    index: Number,
    customFilters: Array,
    globalVariablesData: Array,
    customDashboardData: Object,
    newFilters: Array,
    hasViewPermission: Boolean,
    hasEditPermission: Boolean,
    hasDeletePermission: Boolean,
    hasDuplicatePermission: Boolean,
    isFromApplicationuser: Boolean,
    rawData: Object,
    permissionsSet: Object,
    getRelationshipEntities: Array,
    hide_export: Boolean,
    entity_data_ids: Array,
    getAllEntities: Object,
    externalLimit: Number,
    externalPage: Number,
    hasAddPermission: Boolean,
    customization_id: String,
    notApplyPermissions: Boolean,
    parent_entityDataId: String,
    parent_entityId: String,
    hideAllActions: Boolean,
    permittedEntities: Array,
    permittedRelatedEntities: Array,
    permittedNestedRelatedEntities: Array,
    getAllCompanyTemplatesData: Object,
  },
  async mounted() {
    this.searchDocument = "";
    if (this.searchString) {
      this.search_string = this.searchString;
    }
    let limit = (this.pageSizes && this.pageSizes[0]) || 5;
    this.pageSize = limit;
    this.componentData = JSON.parse(JSON.stringify(this.component));
    if (
      this.componentData?.date_filter_field &&
      this.componentData?.default_date_filter
    ) {
      this.selected_date_picker = this.componentData.default_date_filter;
    }
    if (this.externalLimit && this.externalPage) {
      this.pageSize = this.externalLimit;
      this.currentPage = this.externalPage;
    }

    if (this.customization_id) {
      postAPICall("GET", `/entity-customization/${this.customization_id}`).then(
        (e) => {
          if (e?.data) {
            this.customization = e.data;
          }
        }
      );
    } else {
      postAPICall(
        "GET",
        `/entity-customization/entity/isActive/${this.entity_id}`
      ).then((e) => {
        if (e?.data) {
          this.customization = e.data;
        }
      });
    }
    if (this.$route.name == "entity-custom-dashboard-config") {
      this.applicationUserConfigure = true;
    }
    await this.getNecessaryInfo();
    // bus.$on("apply-custom-filter", (data) => {
    //   if (data) {
    //     if (data.reset) {
    //       this.currentFilter = JSON.parse(JSON.stringify(this.constantFilter));
    //       this.fetchEntitiesDataForTable();
    //     } else if (data.fields) {
    //       let selectedFilters = data.fields.filter(fl => fl.entity_id === this.componentData.entity_id);
    //       if(selectedFilters && selectedFilters.length){
    //         this.applyCustomFilters(selectedFilters);
    //       }
    //       // this.applyCustomFilters(data.fields);
    //     }
    //   }
    // });
    if (
      this.$route.name == "entity-custom-dashboard-config" ||
      this.$route.name == "custom-dashboard-config"
    ) {
      this.isEdit = true;
    }
  },
  beforeDestroy() {
    bus.$off("apply-custom-filter", (data) => {
      console.log("data destory", data);
    });
  },
  filters: {
    truncateHTML(text, maxLength) {
      var tempElement = document.createElement("div");
      tempElement.innerHTML = text;
      var textContent = tempElement.textContent || tempElement.innerText || "";
      var truncatedText = textContent.slice(0, maxLength);
      if (textContent.length > maxLength) {
        truncatedText += "...";
      }
      return truncatedText;
    },
  },
  methods: {
    checkPerimission(access) {
      if (this.isFromApplicationuser) {
        if (access == "BULK DELETE") {
          return false;
        } else if (
          this.currentFilter?.permissions?.access_set &&
          this.currentFilter?._id
        ) {
          if (access == "EDIT") {
            access = "UPDATE";
          }
          return (
            this.currentFilter.permissions.access_set.indexOf(access) != -1
          );
        } else if (
          this.entityPermission &&
          this.entityPermission.other_entities?.[
            this.entity_id
          ]?.permissions.indexOf(access) !== -1
        ) {
          return true;
        }
        return false;
      } else {
        if (access == "BULK DELETE") {
          return true;
        } else if (access == "BULK UPDATE") {
          if (
            this.$route.query.isMenu == "true" &&
            this.getMenu &&
            this.getMenu.menu_list &&
            this.getMenu.menu_list.length
          ) {
            let menuItems = [];
            this.getMenu.menu_list.map((menu) => {
              if (!menu.children?.length) {
                menuItems.push(menu);
              } else {
                menu.children.map((sub) => {
                  if (!sub.children?.length) {
                    menuItems.push(sub);
                  } else {
                    menuItems = [...menuItems, ...sub.children];
                  }
                });
              }
            });
            let menuItem = menuItems.find(
              (e) =>
                e &&
                e.feature == "ENTITIES" &&
                e.isGroup == "ENTITIES" &&
                e.entity_id &&
                (e.entity_id._id
                  ? this.entity_id.toString() == e.entity_id._id.toString()
                  : this.entity_id.toString() == e.entity_id.toString())
            );
            if (
              menuItem &&
              menuItem.bulkUpdate &&
              menuItem.bulkUpdate == true
            ) {
              return true;
            }
            return false;
          }
          return true;
        } else if (
          access == "SEND NOTIFICATION" ||
          access == "LAYOUT OPTIONS"
        ) {
          return true;
        } else if (
          this.currentFilter?.permissions?.access_set &&
          this.currentFilter?._id
        ) {
          if (access == "EDIT") {
            access = "UPDATE";
          }
          return (
            this.currentFilter.permissions.access_set.indexOf(access) != -1
          );
        }
        return true;
      }
    },
    async addEntityData() {
      let query = await this.getNavigationQuery(this.$route.query);
      let viewType = "STANDARD";
      if (this.currentEntity?.entity_type == "INDIVIDUAL") {
        viewType = "PROFILE";
      }
      if (this.isFromApplicationuser) {
        this.$router.push({
          path: `/ap/entity-execute/${this.entity_id}`,
          query: {
            ...query,
            ...{
              menu_id: this.$route.params.menuId,
              layout: viewType,
              type: "s7aLF3NnAQg=",
            },
          },
        });
      } else {
        let UrlPathAdd = "";
        if (this.getIframeUrlInfo != null) {
          UrlPathAdd = `/if/${this.getIframeUrlInfo._id}/entity/edit/${this.entity_id}`;
        } else {
          UrlPathAdd = `/entity/edit/${this.entity_id}`;
        }
        this.$router.push({
          path: UrlPathAdd,
          query: { ...query, ...{ layout: viewType } },
        });
      }
    },
    closeMultiLineData() {
      this.openMultiLineTextDialog = false;
      this.isRichText = null;
    },
    checkNavigationAction(rowData, stepData) {
      if (
        this.isValidURL(this.getFieldValue(rowData, stepData)) &&
        this.isValidHttpUrl(
          this.getValidUrl(this.getFieldValue(rowData, stepData))
        )
      ) {
        let url = this.getValidUrl(this.getFieldValue(rowData, stepData));
        window.open(url, "_blank");
      } else {
        this.$message.info("Invalid URL");
      }
    },
    searchDocumentName() {
      this.filteredDocuments = this.documentsData.filter((document) => {
        return document.name
          .toLowerCase()
          .includes(this.searchDocument.toLowerCase());
      });
    },
    isValidURL(url) {
      let re =
        /[-a-zA-Z0-9@:%_+.~#?&//=]{2,256}\.[a-z]{2,4}\b(\/[-a-zA-Z0-9@:%_+.~#?&//=]*)?/gi;
      if (re.test(url)) {
        return true;
      } else {
        return false;
      }
    },
    getValidUrl(url) {
      if (!url.includes("http://") && !url.includes("https://"))
        return "https://" + url;
      return url;
    },
    isValidHttpUrl(string) {
      let url;
      try {
        url = new URL(string);
      } catch (_) {
        return false;
      }
      return url.protocol === "http:" || url.protocol === "https:";
    },
    openMultiLineData(htmlContent, label, field) {
      this.openMultiLineTextDialog = true;
      this.multiLineTextData = htmlContent;
      this.multiLineTextLabel = label;
      if ("currency_code" in field && "data_type" in field.currency_code) {
        this.isRichText = false;
      } else {
        this.isRichText = true;
      }
    },
    getPlainText(htmlContent) {
      if (!htmlContent) return "";
      const tempDiv = document.createElement("div");
      tempDiv.innerHTML = htmlContent;
      let text = tempDiv.textContent || tempDiv.innerText || "";
      text = text.replace(/\s+/g, " ").trim();
      return text;
    },
    truncateText(text, maxLength = 100) {
      const cleanedText = this.getPlainText(text);
      if (cleanedText.length > maxLength) {
        return cleanedText.substring(0, maxLength) + "...";
      } else {
        return cleanedText;
      }
    },
    truncateHTML(htmlContent, maxLength = 100) {
      const cleanedText = this.getPlainText(htmlContent);
      let truncatedText = cleanedText.slice(0, maxLength);
      if (cleanedText.length > maxLength) {
        truncatedText += "...";
      }
      return truncatedText;
    },
    getCurrencyFormet(step) {
      let field = this.selectedEntityFields.find(
        (e) => e.key && e.key.split("#")[1] && e.key.split("#")[1] == step.id
      );
      if (
        field &&
        field.validations &&
        field.validations.currency &&
        field.validations.locale
      ) {
        return {
          currency: field.validations.currency,
          code: field.validations.locale,
        };
      }
      return {
        currency: "USD",
        code: "en-US",
      };
    },
    getSubField(data, step) {
      let value = 0,
        key = step && step.includes("?") ? step.split("?")[1] : step;
      let field = this.getField(key);
      if (data && step && data[step]) {
        value = data[step];
      }
      if (field?.inputType == "CURRENCY") {
        let type = field.validations.currency
          ? field.validations.currency
          : "USD";
        let locale = field.validations.locale
          ? field.validations.locale
          : "en-US";
        value = new Intl.NumberFormat(locale, {
          style: "currency",
          currency: type,
        }).format(value);
      }
      return value;
    },
    searchEntityData() {
      this.fetchEntitiesDataForTable(this.applied_quick_filters);
    },
    openCountDialog(data, step) {
      this.countComponent = null;
      this.countComponentFilters = [];
      this.countComponentFilters.push({
        field: this.componentData.table_field_group_by,
        operator: "in",
        value: [data._id],
        value_type: "",
        value_source: "",
        data_type: "STRING",
        query_type: "AND",
        value_field: "",
        validations: {
          data_type: "",
        },
        data_source: "field_option",
      });
      if (this.selected_date_picker) {
        let values = this.getDatePickerHelper(this.selected_date_picker);
        this.countComponentFilters.push({
          field: this.componentData.date_filter_field,
          operator: "real_time",
          value: values.value,
          value_type: "",
          value_source: "",
          data_type: "DATE",
          query_type: "AND",
          value_field: "",
          validations: {
            data_type: "",
          },
          selected_date_picker: this.selected_date_picker,
          today: values.today,
          every_year: this.componentData.ignore_year || false,
        });
      }
      if (
        this.componentData.table_field_sub_group_by &&
        step &&
        step.includes("?")
      ) {
        this.countComponentFilters.push({
          field: this.componentData.table_field_sub_group_by,
          operator: "in",
          value: [step.split("?")[0]],
          value_type: "",
          value_source: "",
          data_type: "STRING",
          query_type: "AND",
          value_field: "",
          validations: {
            data_type: "",
          },
          data_source: "field_option",
        });
      }
      if (this.applied_filters && this.applied_filters.length) {
        this.countComponentFilters = [
          ...this.countComponentFilters,
          ...this.applied_filters,
        ];
      }
      this.countComponent = JSON.parse(JSON.stringify(this.componentData));
      if (this.countComponent?.table_field_group_by) {
        delete this.countComponent.table_field_group_by;
      }
      this.showCountDialog = true;
    },
    getLabel(field) {
      let found = this.selectedEntityFields.find((e) => e.key == field);
      return found && found.label ? found.label : "";
    },
    getPropValue(step) {
      if (step.type == "ENTITY") {
        return step.template_id + "#" + step.id + "/name";
      }
      return step.template_id + "#" + step.id;
    },
    async sortChange(a) {
      if (
        this.componentData?.table_field_group_by &&
        a.prop == "entity_label" &&
        this.groupedData.findIndex((e) => e.entity_label) == -1
      ) {
        this.sortBy = "_id";
      } else {
        if (a.prop && a.prop.includes("?")) {
          this.sortBy = "properties." + a.prop.split("?")[1];
        } else {
          this.sortBy = a.prop;
        }
      }
      this.sortOrder = a.order == "ascending" ? "asc" : "desc";
      this.fetchEntitiesDataForTable(this.applied_quick_filters);
      if (a.order == null) {
        this.sortBy = null;
        this.sortOrder = null;
        this.fetchEntitiesDataForTable(this.applied_quick_filters);
      }
    },
    async downloadFile(data) {
      let fileName = data.name;
      fileName = await fileName.replace(/\//g, "-");
      fileName = await fileName.replace(/ /g, "_");
      fileName = await fileName.replace(/[()]/g, "");
      if (data && !data.path) {
        data.path = `template-data-documents/${fileName}`;
      }
      await this.$store.dispatch("templatesData/downloadTemplateDocument", {
        path: data.path,
      });
      await fetch(this.getDownloadUrl, {
        method: "GET",
        responseType: "blob",
      })
        .then((res) => res.blob())
        .then((response) => {
          var fileURL = window.URL.createObjectURL(response);
          const link = document.createElement("a");
          link.href = fileURL;
          link.download = fileName;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        });
      if (this.getDownloadUrl) {
        // window.open(this.getDownloadUrl, "_blank");
      } else {
        this.$notify.error({
          title: "Error",
          message: this.getDownloadError || "Error while downloading",
        });
      }
    },
    compareArraysOfObjects(arr1, arr2) {
      if (arr1.length !== arr2.length) {
        return true;
      }

      for (let i = 0; i < arr1.length; i++) {
        const obj1 = arr1[i];
        const obj2 = arr2[i];

        const keys1 = Object.keys(obj1);
        const keys2 = Object.keys(obj2);

        if (keys1.length !== keys2.length) {
          return true;
        }

        for (let key of keys1) {
          if (
            typeof obj1[key] == "string" &&
            typeof obj2[key] == "string" &&
            obj1[key] !== obj2[key]
          ) {
            return true;
          }
        }
      }

      return false;
    },
    compareObjects(obj1, obj2) {
      const keys1 = Object.keys(obj1);
      const keys2 = Object.keys(obj2);

      if (keys1.length !== keys2.length) {
        return true;
      }

      for (let key of keys1) {
        if (
          typeof obj1[key] != typeof obj2[key] ||
          obj1[key].length !== obj2[key].length
        ) {
          return true;
        }
        if (
          typeof obj1[key] == "object" &&
          obj1[key].length &&
          typeof obj2[key] == "object" &&
          obj2[key].length
        ) {
          if (this.compareArraysOfObjects(obj1[key], obj2[key])) {
            return true;
          }
        }
        if (
          typeof obj1[key] == "string" &&
          typeof obj2[key] == "string" &&
          obj1[key] !== obj2[key]
        ) {
          return true;
        }
      }

      return false;
    },
    async editWorkUserData(row) {
      if (this.isApplicationUser) {
        if (this.checkPerimission("EDIT")) {
          this.entityDataId = row._id;
          let query = await this.getNavigationQuery(this.$route.query);
          let addQuery = {};
          addQuery["dataId"] = row._id;
          addQuery["fromCustom"] = true;
          if (this.isEdit) {
            if (
              this.rawData &&
              this.compareObjects(this.rawData, this.customDashboardData)
            ) {
              this.$notify.info({
                title: "Notify",
                message: "You haven't saved the Edit Data",
              });
              return;
            }
          }
          this.$router.push({
            path: `/ap/entity-execute/${this.entity_id}`,
            query: {
              ...query,
              ...addQuery,
            },
          });
        } else if (this.checkPerimission("VIEW")) {
          this.entityDataId = row._id;
          let query = await this.getNavigationQuery(this.$route.query);
          let addQuery = {};
          addQuery["dataId"] = row._id;
          addQuery["fromCustom"] = true;
          if (this.isEdit) {
            if (
              this.rawData &&
              this.compareObjects(this.rawData, this.customDashboardData)
            ) {
              this.$notify.info({
                title: "Notify",
                message: "You haven't saved the Edit Data",
              });
              return;
            }
          }
          this.$router.push({
            path: `/ap/entity-view/${this.entity_id}`,
            query: {
              ...query,
              ...addQuery,
            },
          });
        } else {
          this.$message({
            message: "You don't have access to view data",
            type: "warning",
          });
          return;
        }
      } else {
        if (
          (this.currentFilter?.permissions?.access_set &&
            this.currentFilter?.permissions?.access_set.indexOf("VIEW") ===
              -1) ||
          this.hasPermission
        ) {
          this.$message({
            message: "You don't have access to view data",
            type: "warning",
          });
          return;
        }
        if (row._id) {
          this.entityDataId = row._id;
          let query = await this.getNavigationQuery(this.$route.query);
          let addQuery = {};
          addQuery["dataId"] = row._id;
          addQuery["fromCustom"] = true;
          if (
            this.currentFilter?._id &&
            this.currentFilter?.entity_id == row.entity_id
          ) {
            addQuery["filter"] = this.currentFilter._id;
          }
          if (this.isEdit) {
            if (
              this.rawData &&
              this.compareObjects(this.rawData, this.customDashboardData)
            ) {
              this.$notify.info({
                title: "Notify",
                message: "You haven't saved the Edit Data",
              });
              return;
            }
          }
          this.$router.push({
            path: `/entity/view/${this.entity_id}`,
            query: { ...query, ...addQuery },
          });
        }
      }
    },
    async viewWorkUserData(row) {
      if (this.isApplicationUser && !this.applicationUserConfigure) {
        if (this.checkPerimission("EDIT")) {
          this.entityDataId = row._id;
          let query = this.getNavigationQuery(this.$route.query);
          let addQuery = {};
          addQuery["dataId"] = row._id;
          addQuery["fromCustom"] = true;
          if (this.isEdit) {
            if (
              this.rawData &&
              this.compareObjects(this.rawData, this.customDashboardData)
            ) {
              this.$notify.info({
                title: "Notify",
                message: "You haven't saved the Edit Data",
              });
              return;
            }
          }
          this.$router.push({
            path: `/ap/entity-execute/${this.entity_id}`,
            query: {
              ...query,
              ...addQuery,
            },
          });
        } else if (this.hasViewPermission) {
          this.entityDataId = row._id;
          let query = this.getNavigationQuery(this.$route.query);
          let addQuery = {};
          addQuery["dataId"] = row._id;
          addQuery["fromCustom"] = true;
          if (this.isEdit) {
            if (
              this.rawData &&
              this.compareObjects(this.rawData, this.customDashboardData)
            ) {
              this.$notify.info({
                title: "Notify",
                message: "You haven't saved the Edit Data",
              });
              return;
            }
          }
          this.$router.push({
            path: `/ap/entity-view/${this.entity_id}`,
            query: {
              ...query,
              ...addQuery,
            },
          });
        } else {
          this.$message({
            message: "You don't have access to view data",
            type: "warning",
          });
          return;
        }
      } else {
        if (
          (this.currentFilter?.permissions?.access_set &&
            this.currentFilter?.permissions?.access_set.indexOf("VIEW") ===
              -1) ||
          this.hasPermission
        ) {
          this.$message({
            message: "You don't have access to view data",
            type: "warning",
          });
          return;
        }
        if (row._id) {
          this.entityDataId = row._id;
          let query = this.getNavigationQuery(this.$route.query);
          let addQuery = {};
          addQuery["dataId"] = row._id;
          addQuery["fromCustom"] = true;
          if (
            this.currentFilter?._id &&
            this.currentFilter?.entity_id == row.entity_id
          ) {
            addQuery["filter"] = this.currentFilter._id;
          }
          if (this.isEdit) {
            if (
              this.rawData &&
              this.compareObjects(this.rawData, this.customDashboardData)
            ) {
              this.$notify.info({
                title: "Notify",
                message: "You haven't saved the Edit Data",
              });
              return;
            }
          }
          this.$router.push({
            path: `/entity/view/${this.entity_id}`,
            query: { ...query, ...addQuery },
          });
        }
      }
    },
    applyQuickFilter() {
      let filters = [];
      for (const property in this.selectedFileds) {
        if (
          this.selectedFileds[property] &&
          this.selectedFileds[property].length
        ) {
          if (!this.selectedFileds[property].includes("ALL")) {
            filters.push({
              field: property,
              operator: "in",
              value: this.selectedFileds[property],
              value_type: "",
              value_source: "",
              data_type: "STRING",
              query_type: "AND",
              value_field: "",
              validations: {
                data_type: "",
              },
              data_source: "field_option",
            });
          }
        }
      }
      if (this.selected_date_picker) {
        let values = this.getDatePickerHelper(this.selected_date_picker);
        filters.push({
          field: this.componentData.date_filter_field,
          operator: "real_time",
          value: values.value,
          value_type: "",
          value_source: "",
          data_type: "DATE",
          query_type: "AND",
          value_field: "",
          validations: {
            data_type: "",
          },
          selected_date_picker: this.selected_date_picker,
          today: values.today,
          every_year: this.componentData.ignore_year || false,
        });
      }
      this.openFilterModal = false;
      this.applied_quick_filters = [...filters];
      this.fetchEntitiesDataForTable(filters);
    },
    openFilterMethod() {
      this.openFilterModal = true;
    },
    applyCustomFilters(fields) {
      this.currentFilter = JSON.parse(JSON.stringify(this.constantFilter));
      let resultFilters = this.getRequiredFields(
        fields,
        this.componentData.entity_id
      );
      if (!this.currentFilter) {
        this.applied_quick_filters = [];
        this.applied_quick_filters = [...fields];
        this.fetchEntitiesDataForTable(this.applied_quick_filters);
      } else if (
        this.currentFilter.filters &&
        this.currentFilter.filters.length
      ) {
        let newFilters = [];
        let commonFilters = [];
        resultFilters.forEach((e) => {
          let exIndex = this.currentFilter.filters.findIndex(
            (el) => el.field === e.field
          );
          if (exIndex === -1) {
            newFilters.push(e);
          } else {
            commonFilters.push(e);
          }
        });
        this.currentFilter.filters.forEach((e) => {
          let exIndex = commonFilters.findIndex((el) => el.field === e.field);
          if (exIndex === -1) {
            newFilters.push(e);
          }
        });
        this.currentFilter.filters = [...commonFilters, ...newFilters];
        if (!this.filter_id) {
          this.currentFilter.filters[0].query_type = "AND";
        }
        this.fetchEntitiesDataForTable();
      } else {
        this.fetchEntitiesDataForTable();
      }
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.fetchEntitiesDataForTable(this.applied_quick_filters);
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.fetchEntitiesDataForTable(this.applied_quick_filters);
      // this.applyQuickFilter();
    },
    deleteComponent() {
      this.$confirm("Are you sure to delete the table?", "Warning", {
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        type: "warning",
      }).then(() => {
        this.$emit("deleteComponent", this.index);
      });
    },
    expendTable() {
      this.componentData.isExpandTable = !this.componentData.isExpandTable;
      this.$emit("expendTable", this.index);
    },
    async updateComponent() {
      this.updateComponentData.component_name =
        this.updateComponentData.component_name.trim();
      if (!this.updateComponentData.component_name) {
        this.$message.error("Please enter table name");
        return;
      }
      let ex = this.customDashboardData.allTableComponents.filter(
        (e, i) =>
          i !== this.index &&
          e.component_name.toLowerCase() ==
            this.updateComponentData.component_name.toLowerCase()
      );
      if (ex && ex.length) {
        this.$notify.error({
          title: "Error",
          message: "Component name already exists",
        });
        return;
      }
      if (
        this.updateComponentData?.date_filter_field &&
        this.updateComponentData?.default_date_filter
      ) {
        this.selected_date_picker =
          this.updateComponentData.default_date_filter;
      }
      if (
        JSON.stringify(this.updateComponentData) !=
        JSON.stringify(this.componentData)
      ) {
        this.componentData = { ...this.updateComponentData };
        await this.getNecessaryInfo();
      } else {
        this.componentData = { ...this.updateComponentData };
      }
      this.$emit("tableUpdate", {
        index: this.index,
        data: this.componentData,
        component: "TABLE",
      });
      this.openDialogVisible = false;
    },
    openConfigDialog() {
      this.updateComponentData = {};
      this.updateComponentData = { ...this.componentData };
      this.openDialogVisible = true;
    },
    async loadData(data) {
      if (data && data.type != "init" && data.type != "sort") {
        this.pageSize = data.pageSize;
        this.currentPage = data.page;
        // await this.fetchAllEmployee();
        await this.fetchEntitiesDataForTable(this.applied_quick_filters);
      }
    },
    getTemplateFields(data) {
      let fields = [];
      data.sections.forEach((e) => {
        fields = [...fields, ...e.fields];
      });

      fields = fields.map((e) => {
        e.template_id = data._id;
        if (e.key && !e.key.includes("#")) {
          e.key = data._id + "#" + e.key;
        }
        e.template_name = data.name;
        e.data_type =
          e.validations && e.validations.type ? e.validations.type : "STRING";
        return e;
      });
      return fields;
    },
    async getNecessaryInfo() {
      if (
        this.isFromApplicationuser &&
        this.getActiveContactType?.contact_type?._id
      ) {
        postAPICall(
          "GET",
          "/application-user/permissions/entity/" +
            this.getActiveContactType.contact_type._id
        ).then((e) => {
          if (e?.data) {
            this.entityPermission = e.data;
          }
        });
      }
      // let entityId = this.componentData.entity_id;
      let filterId = this.componentData.filter_id;
      this.loadingText = "Fetching " + this.componentData.component_name;
      let name = "";
      // await Promise.all([
      //   this.$store.dispatch("entities/fetchEntityById", {
      //     entity_id: entityId
      //   })
      // ]);
      // this.currentEntity = {};
      // if (this.getEntityDataById) {
      // this.currentEntity = this.getEntityDataById;
      this.selectedEntityFields = [];
      // if (this.currentEntity && this.currentEntity.templates) {
      //   this.currentEntity.templates.forEach(e => {
      //     if (e && e.templateInfo && !e.is_repeatable) {
      //       this.selectedEntityFields = [
      //         ...this.selectedEntityFields,
      //         ...this.getTemplateFields(e.templateInfo)
      //       ];
      //     }
      //   });
      // }
      // this.selectedEntityFields = this.setGlobalVariables(
      //   this.selectedEntityFields,
      //   this.globalVariablesData
      // );
      this.currentFilter = {};
      name = this.componentData.component_name;
      if (filterId) {
        // await this.$store.dispatch("filters/getFilterById", filterId);
        let getSingleFilterData = await fetchFilterById(filterId);
        if (getSingleFilterData && getSingleFilterData._id == filterId) {
          this.currentFilter = { ...getSingleFilterData };
          this.constantFilter = JSON.parse(JSON.stringify(getSingleFilterData));
          name = name + " - " + this.currentFilter.filter_name;
          this.setTableColumns();
          if (this.newFilters && this.newFilters.length) {
            let fl = this.currentFilter["filters"] || [];
            this.currentFilter["filters"] = [...fl, ...this.newFilters];
          }
          await this.checkAndGetTableData();
        }
      } else if (this.newFilters) {
        this.currentFilter = {
          ...{},
          ...{
            filters: this.newFilters,
            filter_name: "",
            columns_settings: [],
            quick_filters: [],
          },
        };
        this.setTableColumns();
        await this.checkAndGetTableData();
      } else {
        this.setTableColumns();
        await this.checkAndGetTableData();
      }
      // }
      if (!this.componentData.component_name) {
        this.componentData.component_name = name;
      }
    },
    async checkAndGetTableData() {
      if (
        this.componentData &&
        this.componentData.customFilers &&
        this.componentData.customFilers.length
      ) {
        await this.applyCustomFilters(this.componentData.customFilers);
      } else if (this.customFilters && this.customFilters.length) {
        let selectedFilters = this.customFilters.find(
          (fl) => fl.entity_id === this.componentData.entity_id
        );
        await this.applyCustomFilters(selectedFilters.customFilers || []);
      } else if (
        this.componentData?.date_filter_field &&
        this.componentData?.default_date_filter == this.selected_date_picker
      ) {
        this.applyQuickFilter();
      } else {
        await this.fetchEntitiesDataForTable();
      }
    },
    setTableColumns() {
      this.tableColums = [];
      if (this.currentFilter?.columns_settings?.length > 0) {
        this.tableColums = [...this.currentFilter.columns_settings];
      } else if (
        this.currentEntity?.settings &&
        this.currentEntity.settings.length
      ) {
        this.tableColums = [...this.currentEntity.settings];
      } else {
        (this.currentEntity && this.currentEntity.primaryFields
          ? this.currentEntity.primaryFields
          : []
        ).map(async (data) => {
          this.tableColums.push({
            label: data.label,
            id: data.key,
            template_id: data.template_id,
            type: data.input_type,
            input_type: data.input_type,
          });
        });
      }
      if (this.currentEntity?.templates) {
        //map with latest field type
        this.tableColums = this.mapTableFieldsType(
          this.currentEntity,
          this.tableColums
        );
      }
    },
    async fetchEntitiesDataForTable(filters = []) {
      this.loading = true;
      this.loadingText = "Fetching " + this.componentData.component_name;
      let commonFilters = this.currentFilter?.filters || [];
      filters = this.updateCommonFilters(commonFilters, filters);
      let params = {
        entity_id: this.entity_id,
        template_fields_data: [],
        filters: this.mapDateFilters(
          this.addGlobalFilters(
            filters || [],
            this.entity_id,
            this.checkGlobalFilter
          ),
          this.parent_entityId,
          this.parent_entityDataId
        ),
        limit: this.pageSize,
        page: this.currentPage,
        sortBy: this.sortBy,
        sortOrder: this.sortOrder,
        search_string: this.search_string,
      };
      if (this.entity_data_ids && this.entity_data_ids.length) {
        params["entity_data_ids"] = this.entity_data_ids;
      }
      if (this.componentData?.is_relational_data) {
        params.filters = this.applyRelationalFilter(params.filters);
      }
      this.applied_filters = [];
      this.applied_filters = this.mapDateFilters(
        this.addGlobalFilters(
          filters || [],
          this.entity_id,
          this.checkGlobalFilter
        ),
        this.parent_entityId,
        this.parent_entityDataId
      );
      if (this.componentData?.table_field_group_by) {
        params.group_by = this.componentData?.table_field_group_by;
        params.group_by_fields = this.componentData.table_field_group_by_fields;
        if (this.componentData?.table_field_sub_group_by) {
          params.sub_group_by = this.componentData?.table_field_sub_group_by;
          let subField = this.getField(
            this.componentData?.table_field_sub_group_by
          );
          params.subUnwind = true;
          if (subField?.inputType == "MULTI_SELECT") {
            params.subUnwind = true;
          }
        }
        let field = this.getField(this.componentData?.table_field_group_by);
        params.unwind = true;
        if (field?.inputType == "MULTI_SELECT") {
          params.unwind = true;
        }
        let existed = params.filters.find(
          (e) => e.field == this.componentData?.table_field_group_by
        );
        if (!existed) {
          params.filters.push({
            field: this.componentData.table_field_group_by,
            operator: "exists",
            value_type: "",
            value_source: "",
            data_type: "STRING",
            query_type: "AND",
            value_field: "",
            validations: {
              data_type: "",
            },
            data_source: "",
          });
        }
      }
      this.exportFilters = params.filters;
      await this.$store.dispatch("entities/fetchEntityRecordsForTable", params);
      this.data = [];
      this.groupedData = [];
      if (this.getEntityRecordsForTable) {
        this.data = this.getEntityRecordsForTable.data;
        this.total = this.getEntityRecordsForTable.total
          ? this.getEntityRecordsForTable.total
          : 0;
        this.custom_columns = [];
        if (!this.currentEntity) {
          this.currentEntity = this.getEntityRecordsForTable.selectedEntity;
          if (this.currentEntity && this.currentEntity.templates) {
            this.currentEntity.templates.forEach((e) => {
              if (e && e.templateInfo && !e.is_repeatable) {
                this.selectedEntityFields = [
                  ...this.selectedEntityFields,
                  ...this.getTemplateFields(e.templateInfo),
                ];
              }
            });
            this.setTableColumns();
          }
        }
        this.selectedEntityFields = this.setGlobalVariables(
          this.selectedEntityFields,
          this.globalVariablesData
        );
        if (this.componentData?.table_field_group_by) {
          if (this.componentData?.table_field_sub_group_by) {
            let custom_columns = [];
            this.groupedData = this.getEntityRecordsForTable.data.map(
              (data) => {
                if (data?.properties) {
                  let label = "",
                    total = 0;
                  (data.properties || []).forEach((el) => {
                    if (!label) {
                      label = el.entity_label;
                    }
                    total = total + el.count;
                    let value = el.sub_entity_label
                      ? el.sub_entity_label
                      : el.label;
                    let index = custom_columns.findIndex(
                      (e) => e.value == el.label
                    );
                    if (index == -1 && value) {
                      custom_columns.push({
                        value: el.label,
                        label: value,
                      });
                    }
                    (Object.keys(el) || []).forEach((key) => {
                      if (
                        ["entity_label", "label", "sub_entity_label"].indexOf(
                          key
                        ) == -1
                      ) {
                        data[el.label + "?" + key] = el[key];
                        data[value + "?" + key] = el[key];
                      }
                    });
                  });

                  data.entity_label = label;
                  data.count = total;
                }
                return data;
              }
            );
            if (
              this.componentData?.table_field_group_by_selected_options &&
              this.componentData.table_field_group_by_selected_options.length
            ) {
              custom_columns = custom_columns.filter(
                (e) =>
                  this.componentData.table_field_group_by_selected_options.indexOf(
                    e.value
                  ) != -1
              );
            }
            this.componentData.table_field_group_by_fields
              .filter((e) => e.sub_group)
              .forEach((field) => {
                (custom_columns || []).forEach((col) => {
                  this.custom_columns.push({
                    label: col.label + "-" + field.name,
                    value: col.value + "?" + field.key,
                  });
                });
              });
          } else {
            this.groupedData = this.getEntityRecordsForTable.data;
            this.groupedData = this.groupedData.map((e) => {
              if (e && e.name && e.name.includes("#")) {
                e.name = e.name.split("#")[0];
              }
              return e;
            });
          }
        }
        this.loading = false;
      } else {
        this.loading = false;
      }
    },
    mapGroupData(data, groupByField, columns) {
      let result = [];
      let tid = groupByField.split("#")[0];
      let fd = groupByField.split("#")[1];
      data.forEach((item) => {
        if (
          item.entityData &&
          item.entityData[tid] &&
          item.entityData[tid][fd]
        ) {
          if (
            typeof item.entityData[tid][fd] == "object" &&
            item.entityData[tid][fd].length
          ) {
            item.entityData[tid][fd].forEach((option) => {
              let existedDataIndex = (result || []).findIndex((el) => {
                let e = el.name;
                if (e.includes("#")) {
                  return e.split("#")[1] == item.entityData[tid][fd];
                } else {
                  return e == option;
                }
              });
              if (existedDataIndex == -1) {
                let obj = {};
                obj = { name: option };
                (columns || []).forEach((e) => {
                  let value = 0;
                  if (e && e.key && e.key.includes("#")) {
                    let colTemp = e.key.split("#")[0];
                    let colKey = e.key.split("#")[1];
                    let savedValue = 0;
                    if (item?.entityData?.[colTemp]?.[colKey]) {
                      savedValue = item.entityData[colTemp][colKey];
                    }
                    value = value + savedValue;
                  } else {
                    value = 1;
                  }
                  obj[e.key] = value;
                });
                result.push(obj);
              } else {
                let obj = result[existedDataIndex];
                (columns || []).forEach((e) => {
                  let value = obj[e.key];
                  if (e && e.key && e.key.includes("#")) {
                    let colTemp = e.key.split("#")[0];
                    let colKey = e.key.split("#")[1];
                    let savedValue = 0;
                    if (item?.entityData?.[colTemp]?.[colKey]) {
                      savedValue = item.entityData[colTemp][colKey];
                    }
                    value = value + savedValue;
                  } else {
                    value = value + 1;
                  }
                  obj[e.key] = value;
                });
                result[existedDataIndex] = { ...obj };
              }
            });
          } else {
            let existedDataIndex = (result || []).findIndex((el) => {
              let e = el.name;
              if (e.includes("#")) {
                return e.split("#")[1] == item.entityData[tid][fd];
              } else {
                return e == item.entityData[tid][fd];
              }
            });
            if (existedDataIndex == -1) {
              if (item.entityData[tid][fd + "/name"]) {
                let obj = {};
                obj = {
                  name:
                    item.entityData[tid][fd + "/name"] +
                    "#" +
                    item.entityData[tid][fd],
                };
                (columns || []).forEach((e) => {
                  let value = 0;
                  if (e && e.key && e.key.includes("#")) {
                    let colTemp = e.key.split("#")[0];
                    let colKey = e.key.split("#")[1];
                    let savedValue = 0;
                    if (item?.entityData?.[colTemp]?.[colKey]) {
                      savedValue = item.entityData[colTemp][colKey];
                    }
                    value = value + savedValue;
                  } else {
                    value = 1;
                  }
                  obj[e.key] = value;
                });
                result.push(obj);
              } else {
                let obj = {};
                obj = { name: item.entityData[tid][fd] };
                (columns || []).forEach((e) => {
                  let value = 0;
                  if (e && e.key && e.key.includes("#")) {
                    let colTemp = e.key.split("#")[0];
                    let colKey = e.key.split("#")[1];
                    let savedValue = 0;
                    if (item?.entityData?.[colTemp]?.[colKey]) {
                      savedValue = item.entityData[colTemp][colKey];
                    }
                    value = value + savedValue;
                  } else {
                    value = 1;
                  }
                  obj[e.key] = value;
                });
                result.push(obj);
              }
            } else {
              let obj = result[existedDataIndex];
              (columns || []).forEach((e) => {
                let value = obj[e.key];
                if (e && e.key && e.key.includes("#")) {
                  let colTemp = e.key.split("#")[0];
                  let colKey = e.key.split("#")[1];
                  let savedValue = 0;
                  if (item?.entityData?.[colTemp]?.[colKey]) {
                    savedValue = item.entityData[colTemp][colKey];
                  }
                  value = value + savedValue;
                } else {
                  value = value + 1;
                }
                obj[e.key] = value;
              });
              result[existedDataIndex] = { ...obj };
            }
          }
        }
      });
      return result;
    },
    async fetchAllEntityFilters(id) {
      await this.$store.dispatch("filters/fetchAllEntityFilters", {
        entityId: id,
      });
      this.filters = this.getAllEntityFilters;
    },
    async downloadGroupbyData() {
      let filters = this.applied_filters;
      this.exportFilters = filters;
      let params = {
        entity_id: this.entity_id,
        template_fields_data: (this.currentEntity &&
        this.currentEntity.templates
          ? this.currentEntity.templates
          : []
        ).map((template) => {
          return {
            template_id: template.template_id,
          };
        }),
        filters: this.mapDateFilters(
          this.addGlobalFilters(
            filters || [],
            this.entity_id,
            this.checkGlobalFilter
          ),
          this.parent_entityId,
          this.parent_entityDataId
        ),
        sortBy: this.sortBy,
        sortOrder: this.sortOrder,
        search_string: this.search_string,
      };
      if (this.componentData?.table_field_group_by) {
        let subField = this.getField(this.componentData.table_field_group_by);
        if (subField?.inputType == "MULTI_SELECT") {
          params.unwind = true;
        }
        params.group_by = this.componentData.table_field_group_by;
        params.group_by_fields = this.componentData.table_field_group_by_fields;
        if (this.componentData?.tableFieldSubGroupBy) {
          params.sub_group_by = this.componentData?.tableFieldSubGroupBy;
        } else {
          params.entity_variable_columns =
            this.componentData.filterEntityVariableColumns;
          if (
            this.getField(this.componentData.table_field_group_by)?.entity_id
          ) {
            params.filter_variable_entity = this.getField(
              this.componentData.table_field_group_by
            ).entity_id;
          }
        }
      }
      await this.$store.dispatch("entities/fetchEntityRecordsForTable", params);
      let groupedData = [],
        csvData = [];
      if (this.componentData?.table_field_group_by) {
        if (this.componentData?.tableFieldSubGroupBy) {
          let custom_columns = [];
          groupedData = this.getEntityRecordsForTable.data.map((data) => {
            if (data?.properties) {
              let label = "",
                total = 0;
              (data.properties || []).forEach((el) => {
                if (!label) {
                  label = el.entity_label;
                }
                total = total + el.count;
                let value = el.sub_entity_label
                  ? el.sub_entity_label
                  : el.label;
                let index = custom_columns.findIndex(
                  (e) => e.value == el.label
                );
                if (index == -1 && value) {
                  custom_columns.push({
                    value: el.label,
                    label: value,
                  });
                }
                (Object.keys(el) || []).forEach((key) => {
                  if (
                    ["entity_label", "label", "sub_entity_label"].indexOf(
                      key
                    ) == -1
                  ) {
                    data[el.label + "?" + key] = el[key];
                    data[value + "?" + key] = el[key];
                  }
                });
              });

              data.entity_label = label;
              data.count = total;
            }
            return data;
          });
          if (
            this.componentData?.table_field_group_by_selected_options &&
            this.componentData.table_field_group_by_selected_options.length
          ) {
            custom_columns = custom_columns.filter(
              (e) =>
                this.componentData.table_field_group_by_selected_options.indexOf(
                  e.value
                ) != -1
            );
          }
          this.componentData.table_field_group_by_fields
            .filter((e) => e.sub_group)
            .forEach((field) => {
              (custom_columns || []).forEach((col) => {
                this.custom_columns.push({
                  label: col.label + "-" + field.name,
                  value: col.value + "?" + field.key,
                });
              });
            });
        } else {
          groupedData = this.getEntityRecordsForTable.data;
          groupedData = this.groupedData.map((e) => {
            if (e && e.name && e.name.includes("#")) {
              e.name = e.name.split("#")[0];
            }
            return e;
          });
        }
      }
      groupedData.forEach((data) => {
        let obj = {};
        obj[this.getMainLabel] = data.entity_label
          ? data.entity_label
          : data._id;
        this.componentData.table_field_group_by_fields
          .filter((e) => !e.sub_group)
          .forEach((step) => {
            obj[step.name] = data[step.key];
          });
        this.custom_columns.forEach((step) => {
          obj[step.label] = this.getSubField(data, step.value);
        });
        csvData.push(obj);
      });
      this.downloadExcelFromJson(csvData, "ExcelData");
    },
    downloadExcelFromJson(csvData, fileName) {
      const workbook = XLSX.utils.book_new();
      const worksheet = XLSX.utils.json_to_sheet(csvData);
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

      const excelBuffer = XLSX.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });
      const blob = new Blob([excelBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });

      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileName);

      document.body.appendChild(link);
      link.click();

      setTimeout(() => {
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      }, 0);
    },
    async exportCSV() {
      if (this.componentData.table_field_group_by) {
        await this.downloadGroupbyData();
        return;
      }
      let params = {
        id: this.currentEntity._id,
        entityName: this.currentEntity.name,
        filters: this.mapDateFilters(
          this.exportFilters || [],
          this.parent_entityId,
          this.parent_entityDataId
        ),
        selectedFilter: "",
      };
      this.loadingText = "Exporting";
      this.loading = true;
      await this.$store.dispatch("entities/entityDataExport", params);
      this.loading = false;
      this.loadingText = "Fetching " + this.componentData.component_name;
    },
    async headerDragged(newWidth, oldWidth, column) {
      console.log("column", column);
      let colWidth = {
        ...(this.getCustomDashboardTableWidth || {}),
        ...{
          [column.property]: newWidth,
        },
      };
      this.$store.commit(
        "customDashboard/setCustomDashboardTableWidth",
        colWidth,
        {
          root: true,
        }
      );
      // console.log(
      //   "getCustomDashboardTableWidth",
      //   this.getCustomDashboardTableWidth
      // );
      if (
        this.getEntityDataById?.settings &&
        this.getEntityDataById.settings.length
      ) {
        let entity = this.getEntityDataById;
        let draggedField = entity.settings.find((e) => e.label == column.label);
        let draggedFieldIndex = 0;
        let settings = entity.settings.filter((e) => {
          if (e.label !== draggedField.label) {
            return e;
          } else {
            draggedFieldIndex = entity.settings.indexOf(e);
          }
        });
        draggedField["width"] = newWidth;
        settings.splice(draggedFieldIndex, 0, draggedField);
        let params = {
          name: entity.name,
          description: entity.description,
          entity_type: entity.entity_type,
          templates: entity.templates,
          id: entity._id,
          primaryFields: entity.primaryFields,
          settings: settings,
        };
        await this.$store.dispatch("entities/updateEntityById", params);
      }
    },
    downloadDocumentsPopup(row, step) {
      this.documentsData =
        row.entityData[step.template_id][step.id].files &&
        row.entityData[step.template_id][step.id].files.length
          ? row.entityData[step.template_id][step.id].files
          : row.entityData[step.template_id][step.id];
      this.downloadDocuments = true;
      //this.downloadDocuments = true
    },
    closeDownloadDocuments() {
      this.downloadDocuments = false;
    },
    async onDownloadFile(data) {
      let fileName = data.name;
      fileName = await fileName.replace(/\//g, "-");
      fileName = await fileName.replace(/ /g, "_");
      fileName = await fileName.replace(/[()]/g, "");
      if (data && !data.path) {
        data.path = `template-data-documents/${fileName}`;
      }
      await this.$store.dispatch("templatesData/downloadTemplateDocument", {
        path: data.path,
      });
      await fetch(this.getDownloadUrl, {
        method: "GET",
        responseType: "blob",
      })
        .then((res) => res.blob())
        .then((response) => {
          var fileURL = window.URL.createObjectURL(response);
          const link = document.createElement("a"); // Create an anchor tag
          link.href = fileURL; // Set the URL as the href attribute
          link.download = fileName; // Set the desired filename
          document.body.appendChild(link); // Append the anchor tag to the document body
          link.click(); // Simulate a click on the anchor tag to initiate the download
          document.body.removeChild(link);
        });
      if (this.getDownloadUrl) {
        // window.open(this.getDownloadUrl, "_blank");
        // this.$refs.this.getDownloadUrl.click();
      } else {
        this.$notify.error({
          title: "Error",
          message: this.getDownloadError,
        });
      }
    },
    handleSelectionChange(checkedDocs) {
      this.selectedRowsIds = checkedDocs;
    },
    async openSendNotification(entityData) {
      this.selectedNotifyEntity = entityData;
      this.sendNotificationDialog = true;
    },
    async closeSendNotificationDialog(params) {
      this.loadingText = "Sending Mail(s)...";
      this.loading = true;
      await this.$store.dispatch(
        "entities/sendNotificationToEntityDatausers",
        params
      );
      if (this.getSentEntityDataNotificationStatus) {
        this.loading = false;
        this.$notify({
          title: "Success",
          message: "Sending mail(s) initiated",
          type: "success",
        });
      } else {
        this.loading = false;
        this.$notify({
          title: "Error",
          message:
            this.getSentEntityNotificationStatusErrors ||
            "Error while sending mails",
          type: "error",
        });
      }
      this.paramsData = {};
      this.sendNotificationDialog = false;
    },
    async openBulkSendNotification() {
      this.selectedIds = this.selectedRowsIds.map((row) => {
        return row._id;
      });
      this.bulkSendNotificationDialog = true;
    },
    async closeBulkSendNotificationDialog(params) {
      this.loadingText = "Sending Mail(s)...";
      this.loading = true;
      if (params.attachments && params.attachments?.length) {
        this.loadingText = "Uploading Files";
        let files = params.attachments.map((e) => {
          return {
            name: e.filename,
            mimetype: e.type,
            data: e.content,
            size: e.size,
          };
        });
        let paramsData = {
          files: files,
          path: "email-attachments",
        };
        await this.$store.dispatch(
          "s3FileUpload/uploadMultipleBase64Files",
          paramsData
        );
        if (this.getFileUploadData && this.getFileUploadData.length) {
          params.attachments = this.getFileUploadData;
        }
      }
      await this.$store.dispatch("entities/sendNotificationToAllUsers", params);
      if (this.getSentEntityNotificationStatus) {
        this.loading = false;
        this.$notify({
          title: "Success",
          message: "Sending mails initiated",
          type: "success",
        });
      } else {
        this.loading = false;
        this.$notify({
          title: "Error",
          message: this.getSentEntityNotificationStatusErrors.message,
          type: "error",
        });
      }
      this.paramsData = {};
      this.loading = false;
      this.bulkSendNotificationDialog = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.vue-data-table-default .el-table {
  -webkit-box-shadow: none;
  box-shadow: none;
  border-radius: 0px !important;
}
.custom-dashboard-table {
  border: 1px solid #eaeaea;
  border-radius: 5px;
  // border-top: 1px solid #eaeaea;
  // border-right: 1px solid #eaeaea;
  // border-left: 1px solid #eaeaea;
  min-height: 370px !important;
  margin-bottom: 0px;
  margin-top: 0px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1) !important;
  background-color: #ffffff;
}

.el-table {
  position: relative;
  overflow: auto !important;
  scrollbar-width: thin !important;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  width: 100%;
  max-width: 100%;
  font-size: 14px;
  color: #606266;
  scrollbar-width: thin;
  overflow-y: auto;
}

.icon-style {
  margin-top: 3px;
}

.sidemenu {
  width: 100%;
  height: 100%;
  overflow-x: auto;
  overflow-y: auto;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
  //  direction: rtl;
  border-radius: 10px;
}
.sidemenu::-webkit-scrollbar {
  width: 1em;
  height: 0.5em;
}
.sidemenu::-webkit-scrollbar-horizontal {
  /* Select the down or left scroll button when it's being hovered by the mouse */
  -webkit-border-radius: 10px;
  background: #ffffff;
  -webkit-box-shadow: inset 0 0 6px rgba(172, 169, 169, 0.9);
}
.sidemenu::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  background: #ffffff;
  -webkit-box-shadow: inset 0 0 6px rgba(172, 169, 169, 0.9);
}
.sidemenu::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px #f5f5f5;
  background-color: #f5f5f5;
}
.sidemenu::-webkit-scrollbar-button:single-button {
  width: 16px;
  height: 13px;
  background-color: #bbbbbb;
  display: block;
  border-style: solid;
}

.sidemenu::-webkit-scrollbar-button:single-button:horizontal:decrement {
  // border-width: 8px 8px 8px 8px;
  // border-color: transparent transparent #555555 transparent;
}

.sidemenu::-webkit-scrollbar-button:single-button:horizontal:decrement:hover {
  border-color: transparent transparent #777777 transparent;
}
/* Down */
.sidemenu::-webkit-scrollbar-button:single-button:horizontal:increment {
  //  border-width: 8px 8px 8px 8px;
  // border-color: #555555 transparent transparent transparent;
}

.sidemenu::-webkit-scrollbar-button:horizontal:single-button:increment:hover {
  border-color: #777777 transparent transparent transparent;
}
.custom-pagination {
  background: white;
  padding: 10px;
}
.left-card {
  flex: 1;
  width: 100%;
}
.right-card {
  flex: 4;
  width: 100%;
  justify-content: end;
  align-items: center;

  @media (max-width:650px){
    margin-right:20px !important;
  }
}

.p-button.p-button-secondary.p-button-outlined {
  background-color: transparent;
  color: #64748b;
  border: 1px solid;
  height: 27px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0px 10px 0px 10px !important;
  margin-right: 20px;
}

.table-style {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  margin-top: 10px;
}
//  .scrollbar-container {
//   height: 50vh;
//   scrollbar-width: thin;
//   overflow-y: auto;
//    padding: 0 20px 0 30px;
// }

.date-picker-container {
  display: flex;
}

.picker-options {
  right: 0px !important;
  margin-right: 20px;
}

::v-deep .el-table--scrollable-x .el-table__body-wrapper {
  overflow-x: auto;
}

.btn-sm {
  padding: 0.25rem 0.5rem !important;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
  height: 2em;
}

.fa-file-excel-o {
  margin: 0px 5px;
}

::v-deep .el-table--scrollable-x .el-table__body-wrapper {
  overflow-x: auto !important;
  scrollbar-width: thin !important;
}
.entity-data-table {
  overflow-x: auto !important;
  overflow-y: auto !important;
  scrollbar-width: thin !important;
}

.icon-button {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 2em !important;
  margin-right: 2px;
}
.el-input__icon {
  line-height: 30px !important;
}
</style>

<style>
.el-table .el-table__cell {
  padding: 0px 0px !important;
  min-width: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  text-overflow: ellipsis;
  vertical-align: middle;
  position: relative;
  text-align: left;
}

.el-table td.el-table__cell div {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  font-size: 1em !important;
  font-weight: 300 !important;
}
.pagination-style {
  display: flex;
  justify-content: center;
}
.search-width {
  width: 11vw;

  @media (max-width:650px){
    width:10vw !important;
    margin-right:10px;
  }
}
</style>
